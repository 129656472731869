import { Injectable } from '@angular/core';
import { Account } from '@mkp/account/data-access';
import { Product, ScrollDirection } from '@mkp/shared/util-product';
import { User } from '@user/shared/models';
import { TrackingUtilsService } from './tracking-utils.service';
import {
  Category,
  ContentCategory,
  ContentTypeType,
  EcommerceActionType,
  EcommerceCategory,
  EcommerceEventNameType,
  EcommercePlatformType,
  EventActionType,
  EventCategory,
  EventCategoryType,
  EventFunnelType,
  EventNameType,
  JobAdCategory,
  MainCategory,
  PromotionCategory,
  UserLoginMethodType,
} from '@mkp/tracking/feature-tealium';
import { getFunnelStep } from './funnel-reference';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private readonly _trackingUtilsService: TrackingUtilsService) {}

  pageVisited({
    user,
    account,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return this._trackingUtilsService.getMainCategory(
      user,
      account,
      'page_view',
      'user',
      'user_navigation'
    );
  }

  loginData({
    user,
    account,
    loginMethod,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
    loginMethod: UserLoginMethodType | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(
        user,
        account,
        'user_login',
        'user',
        'user_login'
      ),
      ...(loginMethod ? { user_login_method: loginMethod } : {}),
    };
  }

  signUpData({
    user,
    account,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(
        user,
        account,
        'user_signup',
        'user',
        'user_signup'
      ),
      event_funnel_name: 'user_onboarding',
      event_funnel_step: 4,
    };
  }

  cmsEventData({
    eventCategory,
    contentCategory,
    user,
    account,
  }: {
    eventCategory: EventCategory;
    contentCategory: ContentCategory;
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(
        user,
        account,
        eventCategory.event_name,
        eventCategory.event_category,
        eventCategory.event_action
      ),
      ...contentCategory,
    };
  }

  jobAdBackButtonClickData({
    user,
    account,
    funnelStep,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
    funnelStep: number;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(user, account, 'click', 'interaction', 'click'),
      event_funnel_name: 'job_create',
      event_funnel_step: funnelStep,
      content_type: 'back_button',
    };
  }

  jobAdSuggestButtonClickData({
    user,
    account,
    job_id,
    job_tmp_id,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
    job_id: string | undefined;
    job_tmp_id: string | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(user, account, 'click', 'interaction', 'click'),
      event_funnel_name: 'job_create',
      event_funnel_step: 1,
      content_type: 'suggest_button',
      job_id: job_id,
      job_tmp_id: job_tmp_id,
    };
  }

  jobAdCancelSuggestButtonClickData({
    user,
    account,
    job_id,
    job_tmp_id,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
    job_id: string | undefined;
    job_tmp_id: string | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(user, account, 'click', 'interaction', 'click'),
      event_funnel_name: 'job_create',
      event_funnel_step: 1,
      content_type: 'stop_generate_button',
      job_id: job_id,
      job_tmp_id: job_tmp_id,
    };
  }

  jobCreateStartData({
    user,
    account,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(
        user,
        account,
        'job_ad_create_start',
        'job_ad',
        'job_ad_create_start'
      ),
      event_funnel_name: 'job_create',
      event_funnel_step: 1,
    };
  }

  deleteDraftVacancyData({
    id,
    user,
    account,
  }: {
    id: string;
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(user, account, 'click', 'interaction', 'click'),
      content_type: 'delete_draft',
      job_id: id,
    };
  }

  getJobCreatedData({
    eventName,
    eventAction,
    jobData,
    user,
    account,
  }: {
    eventName: EventNameType;
    eventAction: EventActionType;
    jobData: JobAdCategory;
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(
        user,
        account,
        eventName,
        'job_ad',
        eventAction
      ),
      ...jobData,
      event_funnel_name: 'job_create',
      event_funnel_step: 4,
    };
  }

  jobCreatedData({
    jobData,
    user,
    account,
  }: {
    jobData: JobAdCategory;
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return this.getJobCreatedData({
      eventName: 'job_ad_publish',
      eventAction: 'job_ad_publish',
      jobData,
      user,
      account,
    });
  }

  async unverifiedUserJobCreatedData({
    jobData,
    ecommerceDataFull,
    user,
    account,
  }: {
    jobData: JobAdCategory;
    ecommerceDataFull: EcommerceCategory;
    user: User | null | undefined;
    account: Account | undefined;
  }): Promise<Category> {
    // remove the ecommerce_action information for the unverified checkout event
    const { ecommerce_action: _, ...ecommerceData } = ecommerceDataFull;
    const jobCreatedData = this.getJobCreatedData({
      eventName: 'job_ad_place_order',
      eventAction: 'job_ad_place_order',
      jobData,
      user,
      account,
    });
    return {
      ...jobCreatedData,
      ...ecommerceData,
    };
  }

  jobAdCreateStepData({
    stepIndex,
    nbSuggestions,
    vacancyTemporaryId,
    vacancyId,
    user,
    account,
  }: {
    stepIndex: number;
    nbSuggestions: number;
    vacancyTemporaryId: string;
    vacancyId: string;
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(
        user,
        account,
        'job_ad_create_step',
        'job_ad',
        'job_ad_create_step'
      ),
      event_funnel_name: 'job_application_funnel',
      event_funnel_step: stepIndex,
      job_suggestions: nbSuggestions,
      job_tmp_id: vacancyTemporaryId,
      job_id: vacancyId,
    };
  }

  userRegistrationData({ user }: { user: Pick<User, 'email' | 'id' | 'settings'> }): Category {
    return {
      ...this._trackingUtilsService.getMainCategory(
        user,
        undefined,
        'user_registration',
        'user',
        'user_registration'
      ),
      event_funnel_name: 'user_onboarding',
      event_funnel_step: 2,
    };
  }

  ecommerceEventData({
    ecommerceData,
    jobData,
    promotionData,
    eventName,
    user,
    account,
  }: {
    ecommerceData: EcommerceCategory;
    jobData: JobAdCategory | undefined;
    promotionData: PromotionCategory | undefined;
    eventName: EcommerceEventNameType;
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    const mainCategory = this._trackingUtilsService.getMainCategory(
      user,
      account,
      eventName,
      'ecommerce',
      eventName
    );

    return {
      ...mainCategory,
      ...(jobData ? jobData : {}),
      ...(promotionData ? promotionData : {}),
      ...ecommerceData,
      event_funnel_name: ecommerceData.ecommerce_checkout_funnel_name,
      event_funnel_step: getFunnelStep({
        funnel: ecommerceData.ecommerce_checkout_funnel_name ?? 'ecommerce',
        action: eventName,
      }),
    };
  }

  async productScrollData({
    direction,
    user,
    account,
  }: {
    direction: ScrollDirection;
    user: User | null | undefined;
    account: Account | undefined;
  }): Promise<MainCategory & ContentCategory> {
    return {
      ...this._trackingUtilsService.getMainCategory(user, account, 'click', 'interaction', 'click'),
      event_funnel_name: 'job_create',
      event_funnel_step: 3,
      content_type: `scroll_button_${direction}` as ContentTypeType,
    };
  }

  contactCustomerSupportData({
    user,
    account,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
  }): MainCategory & ContentCategory {
    return {
      ...this._trackingUtilsService.getMainCategory(user, account, 'click', 'interaction', 'click'),
      event_origin: 'jobcloud.ai',
      event_source: 'application',
      content_type: `internal_link_zendesk_open`,
    };
  }

  openLogoGuidelines({
    user,
    account,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
  }): MainCategory & ContentCategory {
    return {
      ...this._trackingUtilsService.getMainCategory(user, account, 'click', 'interaction', 'click'),
      ...this._trackingUtilsService.getLogoGuidelinesEventCategory(),
      event_origin: 'jobcloud.ai',
      event_source: 'application',
      content_type: `internal_link_logoguidelines`,
    };
  }

  getEcommerceStoreCategories({
    products,
    user,
    account,
  }: {
    products: Product[];
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    const { getECommerceCategory, getMainCategory } = this._trackingUtilsService;

    const funnel: EventFunnelType = 'ecommerce_credit_purchase';
    const action: EventActionType = 'ecommerce_product_list_view';
    const eventCategory: EventCategoryType = 'ecommerce';
    const ecommerceAction: EcommerceActionType = 'detail';

    const mainCategory: MainCategory = getMainCategory(
      user,
      account,
      action,
      eventCategory,
      action
    );
    const ecommerceCategory: EcommerceCategory = getECommerceCategory(
      products,
      funnel,
      undefined,
      ecommerceAction
    );

    return {
      ...mainCategory,
      ...ecommerceCategory,
      event_funnel_name: funnel,
      event_funnel_step: getFunnelStep({ funnel, action }),
    };
  }

  getEcommerceCreditUsed({
    products,
    user,
    account,
    platform,
    jobId,
  }: {
    products: Product[];
    user: User | null | undefined;
    account: Account | undefined;
    platform: EcommercePlatformType | undefined;
    jobId: string | undefined;
  }): Category {
    const { getECommerceCategory, getMainCategory } = this._trackingUtilsService;

    const funnel: EventFunnelType = 'job_ad_create';
    const action: EventActionType = 'ecommerce_credit_used';
    const eventCategory: EventCategoryType = 'ecommerce';
    const ecommerceAction: EcommerceActionType = 'purchase';

    const mainCategory: MainCategory = getMainCategory(
      user,
      account,
      action,
      eventCategory,
      action
    );
    const ecommerceCategory: EcommerceCategory = getECommerceCategory(
      products,
      funnel,
      platform,
      ecommerceAction
    );

    return {
      job_id: jobId,
      ...mainCategory,
      ...ecommerceCategory,
      event_funnel_name: funnel,
      event_funnel_step: getFunnelStep({ funnel, action }),
    };
  }
  getEcommerceJobAdPublish({
    user,
    account,
  }: {
    user: User | null | undefined;
    account: Account | undefined;
  }): Category {
    const { getMainCategory } = this._trackingUtilsService;

    const funnel: EventFunnelType = 'job_ad_create';
    const action: EventActionType = 'job_ad_publish';
    const eventCategory: EventCategoryType = 'job_ad';

    const mainCategory: MainCategory = getMainCategory(
      user,
      account,
      action,
      eventCategory,
      action
    );

    return {
      ...mainCategory,
      event_funnel_name: funnel,
      event_funnel_step: getFunnelStep({ funnel, action }),
    };
  }
}
