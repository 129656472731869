import { DEFAULT_LANGUAGE, LanguageCode } from '@mkp/shared/data-access';
import { UserDisplayType, UserRole } from '@mkp/user/data-access';
import { CreateUserPayload, UserTitle } from '@mkp/user/models';

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  emailVerified: boolean;
  title: UserTitle;
  profilePictureMediaApiId: string | null;
  phoneNumber: string | null;
  role?: UserRole | undefined;
  settings: UserSettings;
  _version: string;
}

export interface UserSettings {
  displayType: UserDisplayType;
  language: LanguageCode;
  marketingConsentAcceptedAt: Date | null;
  publicationAiUsageConsent: boolean | null;
}

export const defaultUser: User = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  title: UserTitle.Ms,
  emailVerified: false,
  profilePictureMediaApiId: '',
  phoneNumber: '',
  settings: {
    displayType: UserDisplayType.CustomerJobcloud,
    marketingConsentAcceptedAt: null,
    publicationAiUsageConsent: null,
    language: DEFAULT_LANGUAGE.code,
  },
  _version: '',
} as const;

interface CreateUserSettings {
  // the first marketing consent given is a boolean,
  // after that it is a date in marketingConsentAcceptedAt field.
  marketingConsent: boolean;
  displayType: UserDisplayType;
  language: LanguageCode;
}

type UpdateUserSettings = Partial<NonNullable<UserSettings & CreateUserSettings>>;

export type UpdateUser = Partial<Omit<User, 'settings'>> &
  Pick<User, 'id' | '_version'> & { settings?: UpdateUserSettings };

export const isUpdateUser = (user: UpdateUser | CreateUserPayload): user is UpdateUser =>
  (user as UpdateUser)?.id !== undefined;
