import { environment } from '@env/environment';
import { createSelector } from '@ngrx/store';
import { getShouldLockDebug } from './debug.helper';
import { debugFeature, DebugState, FeatureFlags, getInitialState } from './debug.reducer';

export const selectFeatureFlags = createSelector(
  debugFeature.selectDebugStateState,
  (state: DebugState) =>
    !state || getShouldLockDebug(environment)
      ? getInitialState(environment).featureFlags
      : state.featureFlags
);

export const selectFeatureFlag = (featureFlag: FeatureFlags) =>
  createSelector(selectFeatureFlags, (featureFlags) => featureFlags[featureFlag]);

export const selectIsEditMode = createSelector(
  debugFeature.selectDebugStateState,
  (state: DebugState) => state.editMode
);

export const selectIsDefault = selectFeatureFlag(FeatureFlags.default);

export const selectIsRouterTracingEnabled = selectFeatureFlag(FeatureFlags.routerTracingEnabled);

export const selectIsInvoiceEnabled = selectFeatureFlag(FeatureFlags.isInvoiceEnabled);

export const selectIsNewAccountManagementEnabled = selectFeatureFlag(
  FeatureFlags.isNewAccountManagementEnabled
);

export const selectIsNewCreditAndWalletManagementEnabled = selectFeatureFlag(
  FeatureFlags.isNewCreditAndWalletManagementEnabled
);

export const selectIsNewVacancyPreviewUI = selectFeatureFlag(FeatureFlags.isNewVacancyPreviewUI);

export const selectIsGenerateAbandonedLinkEnabled = selectFeatureFlag(
  FeatureFlags.isGenerateAbandonedLinkEnabled
);

export const selectIsNewAccountManagement = selectFeatureFlag(
  FeatureFlags.isNewAccountManagementEnabled
);

export const selectIsNormalUserAccountManagementEnabled = selectFeatureFlag(
  FeatureFlags.isNormalUserAccountManagementEnabled
);

export const selectTealiumFlags = createSelector(selectFeatureFlags, (featureFlags) => ({
  trackingLink: featureFlags.trackingLink,
  trackingView: featureFlags.trackingView,
  trackingClick: featureFlags.trackingClick,
}));

export const selectIsUserRolesEnabled = selectFeatureFlag(FeatureFlags.isUserRolesEnabled);
