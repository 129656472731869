import { AccountMembership } from '@mkp/account-membership/data-access';
import { createActionGroup, props } from '@ngrx/store';
import { QueryOptions } from '@shared/models';
import { User } from '@user/shared/models';

export const userAccountMembershipActions = createActionGroup({
  source: 'User Account Membership',
  events: {
    'Create User Account Membership': props<{ accountId: string; userEmail: string }>(),
    'Create User Account Memberships Success': props<{
      accountMembership: AccountMembership;
      user: User;
    }>(),
    'Create User Account Membership Failure': props<{ error: { message: string } }>(),
    'User Management Page Open': props<{ query: QueryOptions }>(),
  },
});
